export default defineI18nConfig(() => ({
  legacy: false,
  messages: {
    hu: {
      //BUTONS
      btn_label_1: 'Megnézem',
      btn_label_2: 'Megnézem mindet',
      btn_label_3: 'Részletek',
      btn_label_4: 'Kosárba teszem',

      //SECTIONS
      section_title1: 'Új termékek',
      section_title2: 'Böngész stílus alapján',

    },
    en: {

      //BUTONS
      btn_label_1: 'View',
      btn_label_2: 'View all',
      btn_label_3: 'Details'
    }
  }
}))
